<template>
  <v-card v-if="!$route.meta.isHideBreadcrumb" class="mb-5">
    <v-breadcrumbs :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item exact :to="item.to" :disabled="item.disabled">
          {{ $t(item.text) }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </v-card>
</template>

<script>
import breadcrumbs from '@/router/breadcrumbs'

export default {
  name: 'breadcrumbs',
  data() {
    return {
      currentRouteName: this.$route.name,
      items: []
    }
  },
  mounted() {
    this.setItems()
  },
  watch: {
    $route(route) {
      this.currentRouteName = route.name
      this.setItems()
    }
  },
  methods: {
    displayedCrumbs() {
      // Breadcrumbs object has root and routes. Root is required for us to display home page.
      const routeBreadcrumbList = breadcrumbs.routes

      const foundBreadcrumbs = this.findInNestedByName(
        routeBreadcrumbList,
        this.currentRouteName
      )

      if (!foundBreadcrumbs.length) {
        // eslint-disable-next-line no-console
        console.warn(
          `No breadcrumbs registered for route with name "${this.currentRouteName}"`
        )
      }

      return foundBreadcrumbs
    },
    findInNestedByName(routeBreadcrumbList, name) {
      for (const routeBreadcrumb of routeBreadcrumbList) {
        // We found breadcrumbs for route
        if (routeBreadcrumb.name === name) {
          return [routeBreadcrumb]
        }
        // We didn't find any breadcrumbs for route - means we have to go deeper!
        // Which works only if route breadcrumb has children declared.
        if (!routeBreadcrumb.children) {
          continue
        }
        const result = this.findInNestedByName(routeBreadcrumb.children, name)
        if (result.length) {
          return [routeBreadcrumb, ...result]
        }
      }

      return []
    },
    setItems() {
      const crumbs = this.displayedCrumbs()

      this.items = [
        {
          text: breadcrumbs.root.text,
          to: { name: breadcrumbs.root.name }
        }
      ]

      crumbs.map((crumb) => {
        if (this.items[0].to.name !== crumb.name) {
          if (!crumb.disabled) {
            crumb.to = { name: crumb.name }
          }
          this.items.push(crumb)
        }
      })
    }
  }
}
</script>
