import Vue from 'vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import './plugins/vee-validate'
import './mixin'
import App from './App.vue'

import '@/styles/scss/main.scss'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import '@mdi/font/css/materialdesignicons.min.css'

Vue.config.productionTip = false

Vue.use(PerfectScrollbar)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
