<template>
  <div class="app-loading" v-if="isLoading">
    <v-progress-linear indeterminate color="primary" v-bind="binds" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'app-loading-linear',
  computed: {
    ...mapGetters(['isLoading'])
  },
  props: {
    /**
     * bind options for v-progress-linear
     */
    binds: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
.app-loading {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff71;
  z-index: 5;
}
</style>
