import axios from 'axios'

// create an axios instance
// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const service = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}api/`, // api base url
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // set header token
    if (localStorage.getItem('token') != null) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    }

    return config
  },
  (error) => {
    // alert modal
    if (!error.config?.hideErrorAlert && error.config?.$alert) {
      error.config.$alert('error')
    }

    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => Promise.reject(error.response)
)

export default service
