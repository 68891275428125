<template>
  <div class="cms-layout">
    <drawer
      :sidebar.sync="sidebar"
      :pin.sync="pinSidebar"
      :menuItems="menuItems"
    />
    <bar :sidebar.sync="sidebar" :pin.sync="pinSidebar" />
    <v-main>
      <app-loading-linear />
      <perfect-scrollbar
        ref="mainContent"
        v-scroll.self="onScroll"
        class="cms-layout--main"
      >
        <v-container class="cms-layout--container">
          <breadcrumbs />
          <router-view :key="$route.fullPath" />
        </v-container>
        <to-top ref="toTop" :container="$refs.mainContent" />
      </perfect-scrollbar>
    </v-main>
  </div>
</template>

<script>
import Drawer from './Drawer'
import Bar from './Bar'
import Breadcrumbs from './Breadcrumbs'
import ToTop from './ToTop'
import AppLoadingLinear from '@/components/AppLoadingLinear'

export default {
  name: 'cms-layout',
  components: {
    Drawer,
    Bar,
    Breadcrumbs,
    ToTop,
    AppLoadingLinear
  },
  computed: {
    menuItems() {
      return (
        this.$router.options.routes.find((route) => route.meta?.isDrawer)
          ?.children || []
      )
    }
  },
  data() {
    return {
      sidebar: false,
      pinSidebar: false
    }
  },
  methods: {
    onScroll(e) {
      this.$refs.toTop?.onScroll(e)
    }
  },
  mounted() {
    if (['lg', 'xl'].includes(this.$vuetify.breakpoint.name)) {
      this.pinSidebar = true
    }
  }
}
</script>

<style lang="scss">
.cms-layout {
  &--main {
    overflow-y: auto;
    max-height: calc(100vh - 48px);
  }
  &--container {
    margin-top: 16px;
    max-width: 95%;
  }
}
</style>
