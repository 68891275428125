export default {
  setLogin(state, value) {
    state.isLogin = value
  },
  setPayload(state, value) {
    state.payload = value
  },
  setExp(state, value) {
    state.exp = value
  },
  setUser(state, value) {
    state.user = value
  },
  setPermissions(state, value) {
    state.permissions = value
  }
}
