<template>
  <v-btn fab fixed bottom right color="primary" v-show="show" @click="toTop">
    <v-icon>mdi-chevron-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'to-top',
  props: {
    container: {
      default: () => window
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    onScroll(e) {
      this.show = e.target.scrollTop > 200
    },
    toTop() {
      this.$vuetify.goTo(0, {
        container: this.container,
        easing: 'easeInCubic'
      })
    }
  }
}
</script>
