<template>
  <v-app-bar app dense elevate-on-scroll scroll-target=".cms-layout--main">
    <v-app-bar-nav-icon
      class="d-flex d-md-none"
      @click.stop="$emit('update:sidebar', !sidebar)"
    />
    <v-app-bar-nav-icon
      class="d-none d-md-flex"
      @click.stop="$emit('update:pin', !pin)"
    />
    <v-spacer />
    <language-control />
    <profile />
  </v-app-bar>
</template>

<script>
import LanguageControl from './LanguageControl'
import Profile from './Profile'

export default {
  name: 'bar',
  components: {
    LanguageControl,
    Profile
  },
  props: {
    sidebar: {
      type: Boolean
    },
    pin: {
      type: Boolean
    },
    menuItems: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  align-items: center;
}
</style>
