<template>
  <div class="auth-layout">
    <v-card class="auth-layout--wrapper" :loading="isLoading">
      <template v-slot:progress>
        <v-progress-linear absolute indeterminate bottom color="primary" />
      </template>
      <v-card-text>
        <v-container>
          <v-img
            contain
            class="auth-layout--logo"
            height="100"
            :src="require('@assets/logo.svg')"
          />
          <router-view />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'auth-layout',
  computed: {
    ...mapGetters(['isLoading'])
  }
}
</script>

<style lang="scss">
.auth-layout {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($secondary, 0.05);

  &--wrapper {
    padding: 0 2rem;
  }

  &--logo {
    margin-bottom: 1.4rem;
  }

  &--title {
    margin-bottom: 2rem;
    text-align: center;
  }

  &--footer {
    margin-top: 8px;
    font-weight: bold;
    text-align: center;

    a {
      text-decoration: none;
      color: rgba($primary, 0.8);
    }
  }

  .v-input {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}
</style>
