<template>
  <v-menu bottom offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn class="lang-control" v-on="on">
        <div class="lang-icon" :class="flagIconClass(locale)" />
        <span>{{ languageTitle(locale) }}</span>
      </v-btn>
    </template>
    <v-list flat dense>
      <v-list-item-group v-model="selected" color="primary">
        <v-list-item
          v-for="language in languages"
          :key="language.name"
          @click="setLanguage(language.name)"
        >
          <v-list-item-content>
            <span class="lang-icon" :class="flagIconClass(language.flag)" />
            <span class="lang-label">{{ languageTitle(language.name) }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'language-control',
  computed: {
    languages() {
      return [
        {
          name: 'en',
          flag: 'gb'
        },
        {
          name: 'th',
          flag: 'th'
        }
      ]
    },
    locale() {
      return this.$i18n.locale
    }
  },
  data() {
    return {
      selected: 0
    }
  },
  methods: {
    setLanguage(locale) {
      this.$i18n.locale = locale
    },
    languageTitle(locale) {
      return this.$t(`language.${locale}`)
    },
    flagIconClass(code) {
      code = code === 'en' ? 'gb' : code
      return `flag-icon-${code}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~flag-icon-css/css/flag-icons.min.css';

.lang {
  &-control {
    border-radius: 10rem;
    padding: 0px 5px !important;
  }

  &-icon {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: inline-block;
    background-position: center center;
    background-size: cover;
    margin-right: 10px;
    flex: unset;
  }

  &-label {
    flex: unset;
  }
}
</style>
