<template>
  <v-menu bottom offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-avatar
        color="primary"
        size="35"
        class="hidden-xs-only profile"
        v-on="on"
      >
        <span class="white--text">PO</span>
      </v-avatar>
    </template>
    <v-list flat dense>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="menu in menus"
          :key="menu.name"
          link
          :to="`/${menu.path}`"
          @click="() => onClickItem(menu)"
        >
          <v-list-item-content>
            {{ menuTitle(menu.name) }}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'profile',
  data() {
    return {
      menus: [
        { name: 'profile', path: 'profile' },
        { name: 'logout', path: 'logout' }
      ]
    }
  },
  methods: {
    menuTitle(name) {
      return this.$t(`menu.${name}`)
    },
    onClickItem(menu) {
      if (menu.name === 'logout') {
        this.$store.dispatch('logout')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  margin-left: 8px;
  cursor: pointer;
}
</style>
