import store from '../store'

const isLogin = () =>
  new Promise(async (resolve) => {
    const token = localStorage.getItem('token')
    !store.getters.isLogin && token && (await store.dispatch('verifyToken'))
    resolve(store.getters.isLogin)
  })

const validPermission = (permissions) =>
  !permissions.length ||
  permissions.find((value) => store.getters.permissions.includes(value))

const beforeRouter = async (to, from, next) => {
  let redirectTo

  store.dispatch('showLoading')

  /**
   * validate permission
   */
  if (to.meta?.permissions) {
    // validate token
    if (!(await isLogin())) {
      redirectTo = 'login'
    }

    // validate access permission
    if (!validPermission(to.meta.permissions)) {
      redirectTo = 'exception404'
    }
  }

  /**
   * some route can't be access after login except for logout
   */
  if (
    to.meta?.isAuthInvalid &&
    from.name !== 'logout' &&
    to.name !== 'login' &&
    (await isLogin())
  ) {
    redirectTo = 'exception404'
  }

  if (redirectTo) {
    if (from.name === redirectTo) {
      // hide loading page if from and next is the same route
      store.dispatch('hideLoading')
    }

    return next({ name: redirectTo })
  }

  return next()
}

// eslint-disable-next-line no-unused-vars
const afterRouter = (_to, _from) => {
  // hide loading page
  store.dispatch('hideLoading')
}

export { beforeRouter, afterRouter }
