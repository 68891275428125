import request from '@utils/request'
import { getJwtPayload } from '@utils/function'

export default {
  async login(context, formData) {
    context.dispatch('showLoading')
    return request({
      method: 'post',
      url: 'auth/login',
      data: formData
    })
      .then(({ data }) => {
        localStorage.setItem('token', data.access)
        localStorage.setItem('refreshToken', data.refresh)
        context.dispatch('updateUserInfo')
      })
      .finally(() => {
        context.dispatch('hideLoading')
      })
  },
  forgetPassword(context, data) {
    return new Promise((resolve) => {
      context.dispatch('showLoading')
      setTimeout(async () => {
        resolve(data)
        context.dispatch('hideLoading')
      }, 5000)
    })
  },
  logout(context) {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    context.commit('setLogin', false)
  },
  verifyToken(context) {
    context.dispatch('showLoading')
    return new Promise((resolve) => {
      request({
        method: 'post',
        url: 'auth/token/verify/',
        data: {
          token: localStorage.getItem('token')
        }
      })
        .then(async () => {
          await context.dispatch('updateUserInfo')
          context.dispatch('hideLoading')
          resolve(true)
        })
        .catch(async () => {
          // try to refresh
          const refresh = await context.dispatch('refreshToken')
          resolve(refresh)
        })
    })
  },
  refreshToken(context) {
    context.dispatch('showLoading')
    return new Promise((resolve) => {
      request({
        method: 'post',
        url: 'auth/token/refresh/',
        data: {
          refresh: localStorage.getItem('refreshToken')
        }
      })
        .then(async ({ data }) => {
          localStorage.setItem('token', data.access)
          localStorage.setItem('refreshToken', data.refresh)
          await context.dispatch('updateUserInfo')
          resolve(true)
        })
        .catch(async () => {
          await context.dispatch('logout')
          resolve(false)
        })
        .finally(() => {
          context.dispatch('hideLoading')
        })
    })
  },
  async updateUserInfo(context) {
    const token = localStorage.getItem('token')
    const payload = getJwtPayload(token)
    const { data } = await request({
      method: 'get',
      url: 'auth/profile/'
    })
    const user = data.detail
    const permissions = [...user.permissions]
    delete user.permissions
    return Promise.all([
      context.commit('setLogin', true),
      context.commit('setPayload', payload),
      context.commit('setExp', payload.exp),
      context.commit('setUser', user),
      context.commit('setPermissions', permissions)
    ])
  },
  permissions(context, value) {
    context.commit('setPermissions', value)
  }
}
