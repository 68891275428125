export default {
  root: {
    name: 'home',
    text: 'menu.home'
  },
  routes: [
    {
      name: 'home',
      text: 'menu.home'
    },
    {
      text: 'menu.contact',
      disabled: true,
      children: [
        {
          name: 'contact',
          text: 'menu.contact',
          children: [
            {
              name: 'contactCreate',
              text: 'menu.create'
            },
            {
              name: 'contactEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'document',
          text: 'menu.document',
          disabled: true,
          children: [
            {
              name: 'personalDocument',
              text: 'menu.personalDocument',
              children: [
                {
                  name: 'personalDocumentCreate',
                  text: 'menu.create'
                },
                {
                  name: 'personalDocumentEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'otherDocument',
              text: 'menu.otherDocument',
              children: [
                {
                  name: 'otherDocumentCreate',
                  text: 'menu.create'
                },
                {
                  name: 'otherDocumentEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'otherLicense',
              text: 'menu.otherLicense',
              children: [
                {
                  name: 'otherLicenseCreate',
                  text: 'menu.create'
                },
                {
                  name: 'otherLicenseEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'CompanyCertificate',
              text: 'menu.CompanyCertificate',
              children: [
                {
                  name: 'CompanyCertificateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'CompanyCertificateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'MemorandumOfAssociation',
              text: 'menu.MemorandumOfAssociation',
              children: [
                {
                  name: 'MemorandumOfAssociationCreate',
                  text: 'menu.create'
                },
                {
                  name: 'MemorandumOfAssociationEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'stakeholderDocument',
              text: 'menu.stakeholderDocument',
              children: [
                {
                  name: 'stakeholderDocumentCreate',
                  text: 'menu.create'
                },
                {
                  name: 'stakeholderDocumentEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'TaxRegistrationCertificate',
              text: 'menu.TaxRegistrationCertificate',
              children: [
                {
                  name: 'TaxRegistrationCertificateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'TaxRegistrationCertificateEdit',
                  text: 'menu.edit'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      text: 'menu.audit',
      disabled: true,
      children: [
        {
          name: 'trialBalance',
          text: 'menu.trialBalance',
          children: [
            {
              name: 'trialBalanceCreate',
              text: 'menu.create'
            },
            {
              name: 'trialBalanceEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'auditPlan',
          text: 'menu.auditPlan',
          children: [
            {
              name: 'auditPlanCreate',
              text: 'menu.create'
            },
            {
              name: 'auditPlanEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'audit',
          text: 'menu.audit',
          children: [
            {
              name: 'auditCreate',
              text: 'menu.create'
            },
            {
              name: 'auditEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'asset',
          text: 'menu.asset',
          children: [
            {
              name: 'assetCreate',
              text: 'menu.create'
            },
            {
              name: 'assetEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'liability',
          text: 'menu.liability',
          children: [
            {
              name: 'liabilityCreate',
              text: 'menu.create'
            },
            {
              name: 'liabilityEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'equity',
          text: 'menu.equity',
          children: [
            {
              name: 'equityCreate',
              text: 'menu.create'
            },
            {
              name: 'equityEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'humanAsset',
          text: 'menu.humanAsset',
          children: [
            {
              name: 'humanAssetCreate',
              text: 'menu.create'
            },
            {
              name: 'humanAssetEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'lawsuit',
          text: 'menu.lawsuit',
          children: [
            {
              name: 'lawsuitCreate',
              text: 'menu.create'
            },
            {
              name: 'lawsuitEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'revenue',
          text: 'menu.revenue',
          children: [
            {
              name: 'revenueCreate',
              text: 'menu.create'
            },
            {
              name: 'revenueEdit',
              text: 'menu.edit'
            }
          ]
        },
        {
          name: 'expense',
          text: 'menu.expense',
          children: [
            {
              name: 'expenseCreate',
              text: 'menu.create'
            },
            {
              name: 'expenseEdit',
              text: 'menu.edit'
            }
          ]
        }
      ]
    },
    {
      text: 'menu.sale',
      disabled: true,
      children: [
        {
          name: 'quotationHeader',
          text: 'menu.quotationHeader',
          children: [
            {
              name: 'quotationHeaderCreate',
              text: 'menu.create'
            },
            {
              name: 'quotationHeaderEdit',
              text: 'menu.edit'
            }
          ]
        }
      ]
    },
    {
      name: 'setting',
      text: 'menu.setting',
      disabled: true,
      children: [
        {
          name: 'setting',
          text: 'menu.setting'
        },
        // {
        //   name: 'configuration',
        //   text: 'menu.configurationSetting',
        //   disabled: true,
        //   children: []
        // },
        {
          name: 'plenty',
          text: 'menu.plenty',
          disabled: true,
          children: [
            {
              name: 'businessIndustry',
              text: 'menu.businessIndustry',
              children: [
                {
                  name: 'businessIndustryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'businessIndustryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'businessCategory',
              text: 'menu.businessCategory',
              children: [
                {
                  name: 'businessCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'businessCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'businessType',
              text: 'menu.businessType',
              children: [
                {
                  name: 'businessTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'businessTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'otherDocumentTemplate',
              text: 'menu.otherDocumentTemplate',
              children: [
                {
                  name: 'otherDocumentTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'otherDocumentTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'otherLicenseTemplate',
              text: 'menu.otherLicenseTemplate',
              children: [
                {
                  name: 'otherLicenseTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'otherLicenseTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'customerCompany',
              text: 'menu.customerCompany',
              children: [
                {
                  name: 'customerCompanyCreate',
                  text: 'menu.create'
                },
                {
                  name: 'customerCompanyEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterChartOfAccount',
              text: 'menu.masterChartOfAccount',
              children: [
                {
                  name: 'masterChartOfAccountCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterChartOfAccountEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'questionnaireType',
              text: 'menu.questionnaireType',
              children: [
                {
                  name: 'questionnaireTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'questionnaireTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterQuestionnaire',
              text: 'menu.masterQuestionnaire',
              children: [
                {
                  name: 'masterQuestionnaireCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterQuestionnaireEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'riskType',
              text: 'menu.riskType',
              children: [
                {
                  name: 'riskTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'riskTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'riskCategory',
              text: 'menu.riskCategory',
              children: [
                {
                  name: 'riskCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'riskCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterRiskProfileTemplate',
              text: 'menu.masterRiskProfileTemplate',
              children: [
                {
                  name: 'masterRiskProfileTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterRiskProfileTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterProduct',
              text: 'menu.masterProduct',
              children: [
                {
                  name: 'masterProductCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterProductEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'objective',
              text: 'menu.objective',
              children: [
                {
                  name: 'objectiveCreate',
                  text: 'menu.create'
                },
                {
                  name: 'objectiveEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'taskSubCategory',
              text: 'menu.taskSubCategory',
              children: [
                {
                  name: 'taskSubCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'taskSubCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'activityType',
              text: 'menu.activityType',
              children: [
                {
                  name: 'activityTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'activityTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterActivityProfileTemplate',
              text: 'menu.masterActivityProfileTemplate',
              children: [
                {
                  name: 'masterActivityProfileTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterActivityProfileTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterPropertyAndAgreementHeader',
              text: 'menu.masterPropertyAndAgreementHeader',
              children: [
                {
                  name: 'masterPropertyAndAgreementHeaderCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterPropertyAndAgreementHeaderEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterTaskTemplate',
              text: 'menu.masterTaskTemplate',
              children: [
                {
                  name: 'masterTaskTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterTaskTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterAuditProgramTemplate',
              text: 'menu.masterAuditProgramTemplate',
              children: [
                {
                  name: 'masterAuditProgramTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterAuditProgramTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterPropertyAndAgreementHeader',
              text: 'menu.masterTab',
              children: [
                {
                  name: 'masterPropertyAndAgreementHeaderCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterPropertyAndAgreementHeaderEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterWorkingPaperTemplate',
              text: 'menu.masterWorkingPaperTemplate',
              children: [
                {
                  name: 'masterWorkingPaperTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterWorkingPaperTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterActivityProfileTemplate',
              text: 'menu.masterActivityProfileTemplate',
              children: [
                {
                  name: 'masterActivityProfileTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterActivityProfileTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'assetCategory',
              text: 'menu.assetCategory',
              children: [
                {
                  name: 'assetCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'assetCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'assetType',
              text: 'menu.assetType',
              children: [
                {
                  name: 'assetTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'assetTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'liabilityCategory',
              text: 'menu.liabilityCategory',
              children: [
                {
                  name: 'liabilityCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'liabilityCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'liabilityType',
              text: 'menu.liabilityType',
              children: [
                {
                  name: 'liabilityTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'liabilityTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'equityType',
              text: 'menu.equityType',
              children: [
                {
                  name: 'equityTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'equityTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'equityCategory',
              text: 'menu.equityCategory',
              children: [
                {
                  name: 'equityCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'equityCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'humanAssetCategory',
              text: 'menu.humanAssetCategory',
              children: [
                {
                  name: 'humanAssetCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'humanAssetCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'humanAssetType',
              text: 'menu.humanAssetType',
              children: [
                {
                  name: 'humanAssetTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'humanAssetTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'lawsuitCategory',
              text: 'menu.lawsuitCategory',
              children: [
                {
                  name: 'lawsuitCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'lawsuitCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'lawsuitType',
              text: 'menu.lawsuitType',
              children: [
                {
                  name: 'lawsuitTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'lawsuitTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'revenueCategory',
              text: 'menu.revenueCategory',
              children: [
                {
                  name: 'revenueCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'revenueCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'revenueType',
              text: 'menu.revenueType',
              children: [
                {
                  name: 'revenueTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'revenueTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'expenseCategory',
              text: 'menu.expenseCategory',
              children: [
                {
                  name: 'expenseCategoryCreate',
                  text: 'menu.create'
                },
                {
                  name: 'expenseCategoryEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'expenseType',
              text: 'menu.expenseType',
              children: [
                {
                  name: 'expenseTypeCreate',
                  text: 'menu.create'
                },
                {
                  name: 'expenseTypeEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterPosition',
              text: 'menu.masterPosition',
              children: [
                {
                  name: 'masterPositionCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterPositionEdit',
                  text: 'menu.edit'
                }
              ]
            }
          ]
        },
        {
          name: 'customer',
          text: 'menu.customer',
          disabled: true,
          children: [
            {
              name: 'user',
              text: 'menu.user',
              children: [
                {
                  name: 'userCreate',
                  text: 'menu.create'
                },
                {
                  name: 'userEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'Department',
              text: 'menu.department',
              children: [
                {
                  name: 'departmentCreate',
                  text: 'menu.create'
                },
                {
                  name: 'departmentEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'masterChartOfAccount',
              text: 'menu.masterChartOfAccount',
              children: [
                {
                  name: 'masterChartOfAccountCreate',
                  text: 'menu.create'
                },
                {
                  name: 'masterChartOfAccountEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'questionnaire',
              text: 'menu.questionnaire',
              children: [
                {
                  name: 'questionnaireCreate',
                  text: 'menu.create'
                },
                {
                  name: 'questionnaireEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'product',
              text: 'menu.product',
              children: [
                {
                  name: 'productCreate',
                  text: 'menu.create'
                },
                {
                  name: 'productEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'riskProfileTemplate',
              text: 'menu.riskProfileTemplate',
              children: [
                {
                  name: 'riskProfileTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'riskProfileTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'team',
              text: 'menu.team',
              children: [
                {
                  name: 'teamCreate',
                  text: 'menu.create'
                },
                {
                  name: 'teamEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'activityProfileTemplate',
              text: 'menu.activityProfileTemplate',
              children: [
                {
                  name: 'activityProfileTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'activityProfileTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'workingPaperTemplate',
              text: 'menu.workingPaperTemplate',
              children: [
                {
                  name: 'workingPaperTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'workingPaperTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'auditProgramTemplate',
              text: 'menu.auditProgramTemplate',
              children: [
                {
                  name: 'auditProgramTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'auditProgramTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'taskTemplate',
              text: 'menu.taskTemplate',
              children: [
                {
                  name: 'taskTemplateCreate',
                  text: 'menu.create'
                },
                {
                  name: 'taskTemplateEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'region',
              text: 'menu.region',
              children: [
                {
                  name: 'regionCreate',
                  text: 'menu.create'
                },
                {
                  name: 'regionEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'division',
              text: 'menu.division',
              children: [
                {
                  name: 'divisionCreate',
                  text: 'menu.create'
                },
                {
                  name: 'divisionEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'subDivision',
              text: 'menu.subDivision',
              children: [
                {
                  name: 'subDivisionCreate',
                  text: 'menu.create'
                },
                {
                  name: 'subDivisionEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'businessUnit',
              text: 'menu.businessUnit',
              children: [
                {
                  name: 'businessUnitCreate',
                  text: 'menu.create'
                },
                {
                  name: 'businessUnitEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'assetGroup',
              text: 'menu.assetGroup',
              children: [
                {
                  name: 'assetGroupCreate',
                  text: 'menu.create'
                },
                {
                  name: 'assetGroupEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'liabilityGroup',
              text: 'menu.liabilityGroup',
              children: [
                {
                  name: 'liabilityGroupCreate',
                  text: 'menu.create'
                },
                {
                  name: 'liabilityGroupEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'humanAssetGroup',
              text: 'menu.humanAssetGroup',
              children: [
                {
                  name: 'humanAssetGroupCreate',
                  text: 'menu.create'
                },
                {
                  name: 'humanAssetGroupEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'lawsuitGroup',
              text: 'menu.lawsuitGroup',
              children: [
                {
                  name: 'lawsuitGroupCreate',
                  text: 'menu.create'
                },
                {
                  name: 'lawsuitGroupEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'revenueGroup',
              text: 'menu.revenueGroup',
              children: [
                {
                  name: 'revenueGroupCreate',
                  text: 'menu.create'
                },
                {
                  name: 'revenueGroupEdit',
                  text: 'menu.edit'
                }
              ]
            },
            {
              name: 'expenseGroup',
              text: 'menu.expenseGroup',
              children: [
                {
                  name: 'expenseGroupCreate',
                  text: 'menu.create'
                },
                {
                  name: 'expenseGroupEdit',
                  text: 'menu.edit'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'task',
      text: 'menu.task',
      disabled: true,
      children: [
        {
          name: 'taskCreate',
          text: 'menu.create'
        },
        {
          name: 'taskEdit',
          text: 'menu.edit'
        }
      ]
    }
  ]
}
