import { extend } from 'vee-validate'
import {
  required,
  email,
  min_value,
  max_value,
  confirmed,
  numeric,
  digits,
  double,
  between
} from 'vee-validate/dist/rules'

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    )

    return {
      valid: regex.test(value)
    }
  },
  message: 'The {_field_} field must contain only decimal values'
})

const rules = {
  required,
  email,
  min_value,
  max_value,
  confirmed,
  numeric,
  digits,
  double,
  between
}
Object.keys(rules).map((key) => extend(key, rules[key]))
